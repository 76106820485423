body {
  background: white;
}

.search {
  width: 500px;
}

.search-div {
  display: flex;
  margin-top: 15px;
  justify-content: center;
}

video {
  border-radius: 20px; 
}

.video-player {
  margin-top: 15px;
}

.container.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shots-table {
  margin-top: 15px;
}

th {
  background-color: rgb(228, 228, 228) !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.number-filter-input.form-control {
  width: 75% !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}